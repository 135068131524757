<template>
  <Layout>
    <PageHeader
      :title="title"
      moda_title="Datos del operador"
      ref="pageForm"
      :items="items"
    >
    </PageHeader>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <Tooltip>
                <p>
                  En este apartado podrás ver el acumulado de GANANCIAS, PROFIT,
                  y PERDIDAS por cada jugador.
                </p>
                <p>
                  Al darle click al 'User ID' podrás ver las transacciones de
                  ese jugador.
                </p>
              </Tooltip>
            </div>
            <div class="row mt-4">
              <div class="col-md-3 col-sm-9">
                <label class="d-inline-flex align-items-center w-100">
                  <span class="m-2">{{ $t('filter.from') }}: </span>
                  <vs-input v-model="filterData.fromDate" type="date" />
                  <vs-input
                    class="ms-2"
                    type="time"
                    v-model="filterData.fromHour"
                    v-if="filterData.fromDate"
                  />
                </label>
              </div>
              <div class="col-md-3 col-sm-9">
                <label class="d-inline-flex align-items-center w-100">
                  <span class="m-2">{{ $t('filter.until') }}: </span>
                  <vs-input v-model="filterData.toDate" type="date" />
                  <vs-input
                    class="ms-2"
                    type="time"
                    v-model="filterData.toHour"
                    v-if="filterData.toDate"
                  />
                </label>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-4" v-if="checkAdmins()">
                <label class="d-inline-flex align-items-center w-100">
                  {{ $t('filter.client') }}:
                  <select
                    v-model="filterData.clientId"
                    @change="getOperatorClient(true)"
                    name="client"
                    class="form-select form-select-sm m-2 border-0 shadow-lg"
                    placeholder="Seleccione.."
                  >
                    <option disabled selected>
                      {{ $t('helpers.select') }}...
                    </option>
                    <option
                      v-for="(item, index) in clients"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </label>
              </div>
              <div
                class="col-sm-12 col-md-4"
                v-if="userRole !== 'OPERATOR' && filterData.clientId"
              >
                <label class="d-inline-flex align-items-center w-100">
                  {{ $t('filter.operator') }}:
                  <select
                    v-model="filterData.operatorId"
                    name="client"
                    class="form-select form-select-sm m-2 border-0 shadow-lg"
                    placeholder="Seleccione.."
                  >
                    <option disabled selected>
                      {{ $t('helpers.select') }}...
                    </option>
                    <option
                      v-for="(item, index) in operators"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </label>
              </div>
              <div class="col-sm-12 col-md-4" v-if="filterData.operatorId">
                <label class="d-inline-flex align-items-center w-100">
                  {{ $t('filter.currency') }}:
                  <select
                    v-model="filterData.currency"
                    name="currency"
                    class="form-select form-select-sm m-2 border-0 shadow-lg"
                    placeholder="Seleccione.."
                  >
                    <option disabled selected>
                      {{ $t('helpers.select') }}...
                    </option>
                    <option value="*">ALL</option>
                    <option
                      v-for="item in currencies"
                      :key="item._id"
                      :value="item._id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </label>
              </div>
              <div
                class="col-sm-12 col-md-12 d-flex justify-content-start"
                v-if="filterData.operatorId"
                @change="getGames"
              >
                <label
                  class="d-inline-flex align-items-center"
                  style="width: max-content"
                >
                  {{ $t('filter.gameType') }}:
                </label>
                <select
                  v-model="filterData.gameType"
                  name="client"
                  class="form-select form-select-sm my-2 ms-2 border-0 shadow-lg w-50"
                  placeholder="Seleccione.."
                >
                  <option disabled selected>
                    {{ $t('helpers.select') }}...
                  </option>
                  <option
                    v-for="(item, index) in gameTypes"
                    :key="index"
                    :value="item.value"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div class="col-sm-12 col-md-4" v-if="filterData.gameType">
                <label class="d-inline-flex align-items-center w-100">
                  JUEGO:
                  <select
                    v-model="filterData.rouletteId"
                    name="client"
                    class="form-select form-select-sm m-2 shadow-lg border-0"
                    placeholder="Seleccione.."
                  >
                    <option disabled selected>
                      {{ $t('helpers.select') }}...
                    </option>
                    <option value="*">ALL</option>
                    <option
                      v-for="({ _id, name }, index) in roulettes"
                      :key="index"
                      :value="_id"
                    >
                      {{ name }}
                    </option>
                  </select>
                </label>
              </div>
            </div>
            <div class="col-sm-12 col-md-4">
              <label class="d-inline-flex align-items-center w-100 m-1">
                <vs-tooltip>
                  <span>{{ $t('filter.player') }} Admin:</span>
                  <vs-switch v-model="playerAdmin" class="m-1" />
                  <template #tooltip
                    >Habilitar para filtrar con jugadores de test</template
                  >
                </vs-tooltip>
              </label>
            </div>
            <div class="d-flex align-items-center mt-2">
              <vs-select
                placeholder="API VERSION"
                v-model="filterData.apiVersion"
              >
                <vs-option label="V1" value="V1">V1</vs-option>
                <vs-option label="V2" value="V2">V2</vs-option>
              </vs-select>
              <vs-input
                placeholder="USERNAME"
                border
                class="shadow-lg me-2"
                v-model="filterData.playerId"
              />
              <vs-button :disabled="!filterData.currency">
                <downloadexcel
                  :fetch="exportData"
                  :fields="json_fields"
                  :name="fileName"
                >
                  {{ $t('filter.downloadExcel') }}
                </downloadexcel>
              </vs-button>
              <DateRange @handleRadio="handleRadio" />
              <vs-button type="button" @click="filtrar()">{{
                $t('filter.filter')
              }}</vs-button>
              <vs-button
                type="button"
                success
                @click="cancelFilter()"
                :disabled="!Object.entries(filterData).length"
              >
                {{ $t('filter.cancel') }}
              </vs-button>
            </div>
            <div class="text-center" v-if="fetching">
              <b-spinner type="grow" label="Spinning"></b-spinner>
              {{ $t('helpers.loading') }}...
            </div>
            <div class="row mt-2" v-if="tableData.length">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    {{ $t('filter.show') }}&nbsp;
                    <vs-select v-model="perPage" color="dark" class="shadow-lg">
                      <vs-option
                        v-for="(op, index) in pageOptions"
                        :key="index"
                        :label="op"
                        :value="op"
                        >{{ op }}</vs-option
                      >
                    </vs-select>
                    &nbsp;{{ $t('filter.entries') }}
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center m-2">
                    {{ $t('filter.search') }}:
                    <vs-input
                      v-model="filter"
                      type="search"
                      primary
                      class="shadow-lg"
                    ></vs-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <b-overlay :show="loading" rounded="lg">
              <div class="table-responsive mb-0" v-if="tableData.length">
                <b-table
                  :items="tableData"
                  :fields="makeFields()"
                  responsive="sm"
                  :filter-included-fields="filterOn"
                  :filter="filter"
                  :sort-by="sortBy"
                  :sort-desc="sortDesc"
                  :foot-clone="Object.entries(total).length ? true : false"
                >
                  <template #cell(userId)="data">
                    <span
                      @click="setTransactionsPlayer(data.item)"
                      style="cursor: pointer"
                      class="text-primary fw-bolder"
                    >
                      {{ data.item.userId }}
                    </span>
                  </template>
                  <template #cell(currency)="data">
                    <span>
                      {{ data.item.currency }}
                    </span>
                  </template>
                  <template #cell(details)="data">
                    <div>
                      <b-button
                        variant="outline-primary"
                        @click="openDetails(data.item)"
                        >{{ $t('helpers.look') }}</b-button
                      >
                    </div>
                  </template>
                  <template #cell(bets)="data">
                    {{ data.item.bets && formatNumber(data.item.bets) }}
                  </template>
                  <template #cell(wins)="data">
                    {{ data.item.wins && formatNumber(data.item.wins) }}
                  </template>
                  <template #cell(profit)="data">
                    {{ data.item.profit && formatNumber(data.item.profit) }}
                  </template>
                  <template #cell(USDBETS)="data">
                    {{
                      data.item.currency &&
                      exchangeTransform(
                        data.item.totalBet,
                        data.item.currency.usdExchange
                      )
                    }}
                  </template>
                  <template #cell(USDWIN)="data">
                    {{
                      filterData.currency
                        ? data.item.currency &&
                          exchangeTransform(
                            data.item.totalWin,
                            data.item.currency.usdExchange
                          )
                        : '-'
                    }}
                  </template>
                  <template #cell(USDPROFIT)="data">
                    {{
                      filterData.currency
                        ? data.item.currency &&
                          exchangeTransform(
                            data.item.totalProfit,
                            data.item.currency.usdExchange
                          )
                        : '-'
                    }}
                  </template>
                  <template #foot(username) v-if="Object.entries(total).length">
                    <span>TOTAL</span>
                  </template>
                  <template #foot(rounds) v-if="Object.entries(total).length">
                    <span> {{ formatNumber(total.totalizedRounds) }}</span>
                  </template>
                  <template #foot(bets) v-if="Object.entries(total).length">
                    <span> {{ formatNumber(total.totalizedBet) }}</span>
                  </template>
                  <template #foot(wins) v-if="Object.entries(total).length">
                    <span> {{ formatNumber(total.totalizedWin) }}</span>
                  </template>
                  <template #foot(profit) v-if="Object.entries(total).length">
                    <span> {{ formatNumber(total.totalizedProfit) }}</span>
                  </template>
                  <!-- BLANK CELLS -->
                  <template #foot(userId) v-if="Object.entries(total).length">
                    {{ '-' }}
                  </template>
                  <template #foot(operator) v-if="Object.entries(total).length">
                    {{ '-' }}
                  </template>
                  <template
                    #foot(currency.short)
                    v-if="Object.entries(total).length"
                  >
                    {{ '-' }}
                  </template>
                  <template
                    #foot(currency.usdExchange)
                    v-if="Object.entries(total).length"
                  >
                    {{ '-' }}
                  </template>
                  <template #foot(USDBETS) v-if="Object.entries(total).length">
                    {{ '-' }}
                  </template>
                  <template #foot(USDWIN) v-if="Object.entries(total).length">
                    {{ '-' }}
                  </template>
                  <template #foot(USDPROFIT)>
                    {{ '-' }}
                  </template>
                </b-table>
                <div v-if="!tableData.length" class="text-center">
                  {{ $t('tableReports.noResults') }}
                </div>
              </div>
            </b-overlay>
            <div class="text-center" v-if="isLoadingTotal">
              <span>Totalizando...</span>
              <b-spinner type="grow" label="Loading..."></b-spinner>
            </div>
            <p
              class="text-center my-2"
              v-if="searchActive && !tableData.length && !loading"
            >
              {{ $t('tableReports.noResults') }}
            </p>
            <div class="row" v-if="tableData.length">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <button
                      class="btn btn-secondary mx-2 uil-angle-left"
                      @click="beforePage"
                      :disabled="!skip"
                    ></button>
                    <span class="d-inline-flex align-items-center">
                      <span class="text-secondary">{{ getPage }}</span>
                    </span>
                    <button
                      class="btn btn-primary mx-2 uil-angle-right"
                      @click="nextPage"
                      :disabled="tableData.length < limit"
                    ></button>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/main';
import PageHeader from '@/components/page-header-simple';
import downloadexcel from 'vue-json-excel';
import Swal from 'sweetalert2';
import appConfig from '@/app.config';
import { breadCrumbData } from '../../../../helpers/breadcrumbs/breadCrumbs';
import Tooltip from '../../../../components/tooltip.vue';
import DateRange from '../components/DateRange.vue';
import { onlyAdmins } from '../../../../router/guards/roles';
import { mapActions, mapGetters } from 'vuex';

/**
 * Users-table component
 */
export default {
  page: {
    title: 'Players Table',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    downloadexcel /* TotalRegister , pagination */,
    Tooltip,
    DateRange,
  },
  data() {
    return {
      // loadingTotal
      isLoadingTotal: false,
      // PLAYER ADMIN
      playerAdmin: false,
      //
      dateWasChanged: false,
      total: {},
      //
      searchActive: false,
      fetching: false,
      //

      fileName: 'Jugadores.xls',
      json_fields: {
        [this.$t('betDetail.user')]: 'username',
        [this.$t('tableReports.userId')]: 'userId',
        [this.$t('tableReports.operator')]: 'operator',
        [this.$t('tableReports.roundCount')]: 'roundCount',
        [this.$t('betDetail.totalBet')]: 'totalBet',
        'Total ganancias': 'totalWin',
        [this.$t('tableReports.profit')]: 'totalProfit',
        [this.$t('tableReports.currency')]: 'currency.short',
        [this.$t('tableReports.rateUSD')]: 'currency.usdExchange',
        [this.$t('tableReports.betUSD')]: 'USDBETS',
        [this.$t('tableReports.winUSD')]: 'USDWIN',
        [this.$t('tableReports.totalProfitUSD')]: 'USDPROFIT',
      },
      tableData: [],
      totalRegister: 0,
      totalPages: '...',

      title: this.$t('sidebar.reports.subItems.players'),
      items: breadCrumbData.reportPlayers,
      loading: false,
      filterData: {
        apiVersion: 'V2',
      },
      dataDetails: {},
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100, 1000, 'ALL'],
      filter: null,
      filterOn: ['userId', 'username'],
      clients: [],
      operators: [],
      roulettes: [],
      sortBy: 'name',
      sortDesc: false,
      // fields: ,

      clientFilter: null,
      playerId: null,
      currencies: [],
      skip: 0,
      limit: 10,
      disabledNextPage: null,
      disabledPreviousPage: null,
      downloadSelected: 'true',
      userData: '',
      userRole: '',
      gameType: null,

      // ROLES
      readAllRoles: onlyAdmins,
    };
  },
  computed: {
    ...mapGetters({
      mapCurrencies: 'currencies/getCurrencies',
      mapOperators: 'operators/getOperators',
      getMapClients: 'clients/getClients',
      formatNumber: 'reports/formatNumbers',
      mapClientRoulettes: 'clients/getClientRoulettes',
      gameTypes: 'reports/getGamesTypes',
      mapGames: 'games/getGames',
      mapGgrPlayers: 'reports/mapGgrPlayers',
      mapGgrPlayersTotalized: 'reports/mapGgrPlayersTotalized',
      userIsClient: 'user/isClient',
    }),
    getPage() {
      if (this.tableData.length === this.limit)
        return `Page: ${this.currentPage} ...`;
      if (this.tableData.length < this.limit)
        return `... Page: ${this.currentPage}`;
      return '...';
    },
  },
  mounted() {
    // Set the initial number of items
    this.playerId = this.$route.params.id ? this.$route.params.id : null;
    this.totalRows = this.items.length;

    this.getUserRole();
    this.getCurrencies();
  },
  methods: {
    // HANDLE DATA
    async filtrar() {
      this.total = {};
      this.searchActive = true;
      this.loading = true;

      const filter = {
        page: this.currentPage,
        limit: this.limit,
        ...this.filterData,
      };

      try {
        await this.fetchGgrByPlayers(filter);

        this.tableData = this.mapGgrPlayers;

        this.loading = false;
        this.isLoadingTotal = true;

        await this.fetchGgrByPlayersTotalized(filter);

        this.total = this.mapGgrPlayersTotalized;

        this.isLoadingTotal = false;
      } catch (error) {
        console.log('ERROR FILTERING REPORTS PLAYERS', error);
      }
    },
    async getClients() {
      try {
        await this.fetchClients();
        this.clients = this.getMapClients;
      } catch (error) {
        console.log('ERROR GET CLIENTS', error);
      }
    },
    async getOperatorClient(filter) {
      const id = filter ? this.filterData.clientId : this.userData.client;
      try {
        const options = { params: { client: id } };
        await this.fetchOperators({ options });
        this.operators = this.mapOperators;
      } catch (error) {
        console.log('ERROR GET OPERATOR CLIENT', error);
      }
    },
    async getCurrencies() {
      try {
        await this.fetchCurrencies({
          role: this.userData.role.name,
          user: this.userData,
        });
        this.currencies = this.mapCurrencies;
      } catch (err) {
        console.log(err);
      }
    },
    // EXPORT XLS
    async exportData() {
      if (!this.filterData.fromDate || !this.filterData.toDate)
        return Swal.fire(
          'Es necesario seleccionar un rango de fecha',
          '',
          'info'
        );

      this.fetching = true;

      const filter = {
        page: this.currentPage,
        limit: this.limit,
        ...this.filterData,
      };

      await this.fetchGgrByPlayers(filter);

      if (this.mapGgrPlayers.length) {
        const formattData = [];

        this.mapGgrPlayers.forEach((element) => {
          formattData.push({
            ...element,
            // formatNumber(data.item.totalBet)
            totalBet: this.formatNumber(element.totalBet),
            totalWin: this.formatNumber(element.totalWin),
            totalProfit: this.formatNumber(element.totalProfit),
            USDBETS:
              element.currency &&
              String(
                this.exchangeTransform(
                  element.totalBet,
                  element.currency.usdExchange
                )
              ).replace('.', ','),
            USDWIN:
              element.currency &&
              String(
                this.exchangeTransform(
                  element.totalWin,
                  element.currency.usdExchange
                )
              ).replace('.', ','),
            USDPROFIT:
              element.currency &&
              String(
                this.exchangeTransform(
                  element.totalProfit,
                  element.currency.usdExchange
                )
              ).replace('.', ','),
          });
        });

        this.fetching = false;

        return formattData;
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'No se encuentran datos asociados',
          showConfirmButton: false,
          timer: 2000,
        });
      }
      this.fetching = false;
    },
    // NAVIGATION
    setTransactionsPlayer(item) {
      const playerId = item.userId;

      this.$router.push({ path: '/reports/transations', query: { playerId } });
    },
    // PAGINATION
    handleChange() {
      this.skip = 0;
      this.limit = this.perPage;
      this.filtrar();
    },
    nextPage() {
      this.skip += this.perPage;
      // const { updatedAt } = this.tableData[this.tableData.length - 1];
      // this.filterData.from = updatedAt;
      this.currentPage++;
      this.filtrar();
    },
    beforePage() {
      // this.filterData.from = "";

      this.skip -= this.perPage;

      this.currentPage--;
      this.filtrar();
    },
    change() {
      this.filtrar();
    },
    // TOOLS
    cancelFilter() {
      this.searchActive = false;
      this.filterData = {
        apiVersion: 'V2',
      };
      this.getUserRole();
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    exchangeTransform(amount, usdExchange) {
      if (amount && usdExchange) {
        if (usdExchange > 1) {
          return this.formatNumber(amount / usdExchange);
        }
        return this.formatNumber(usdExchange * amount);
      }
      return 0;
    },
    daysInMonth(month, year) {
      return new Date(year, month + 1, 0).getDate();
    },
    async handleRadio(value) {
      this.picked = value;
      switch (this.picked) {
        case this.$t('helpers.onPage'):
          break;
        case this.$t('helpers.today'): {
          const initialDate = new Date().toJSON().split('T')[0];
          this.filterData = {
            ...this.filterData,
            fromDate: initialDate,
            toDate: initialDate,
          };
          break;
        }
        case this.$t('helpers.month'): {
          let from = 1;
          let to = this.daysInMonth(
            new Date().getMonth(),
            new Date().getFullYear()
          );
          const initialDate = new Date().toJSON().split('T')[0].split('-');
          let fromDate = [initialDate[0], initialDate[1], from].join('-');
          let toDate = [initialDate[0], initialDate[1], to].join('-');
          this.filterData = { ...this.filterData, fromDate, toDate };
          break;
        }
        default:
          break;
      }
      return true;
    },
    makeFields() {
      return [
        {
          key: 'name',
          sortable: true,
          label: this.$t('betDetail.user').toUpperCase(),
          class: 'space-nowrap',
        },
        // {
        //   key: "userId",
        //   sortable: true,
        //   label: this.$t("tableReports.userId").toUpperCase(),
        //   class: "space-nowrap text-end",
        // },
        {
          key: 'operator',
          sortable: true,
          label: this.$t('tableReports.operator').toUpperCase(),
          class: 'space-nowrap',
        },
        {
          key: 'rounds',
          sortable: true,
          label: this.$t('tableReports.roundCount').toUpperCase(),
          class: 'space-nowrap text-end',
        },
        {
          key: 'bets',
          sortable: true,
          label: this.$t('betDetail.totalBet').toUpperCase(),
          class: 'space-nowrap text-end',
        },
        {
          key: 'wins',
          sortable: true,
          label: this.$t('betDetail.totalWin').toUpperCase(),
          class: 'space-nowrap text-end',
        },
        {
          key: 'profit',
          sortable: true,
          label: this.$t('tableReports.profit').toUpperCase(),
          class: 'space-nowrap text-end',
        },
        {
          key: 'currency',
          sortable: true,
          label: this.$t('tableReports.currency').toUpperCase(),
          class: 'space-nowrap text-center',
        },
        // {
        //   key: "currency.usdExchange",
        //   sortable: true,
        //   label: this.$t("tableReports.rateUSD").toUpperCase(),
        //   class: "space-nowrap text-end",
        // },
        // {
        //   key: "USDBETS",
        //   sortable: true,
        //   label: this.$t("tableReports.betUSD").toUpperCase(),
        //   class:
        //     this.filterData.currency && this.filterData.currency !== "*"
        //       ? "space-nowrap text-end"
        //       : "d-none",
        // },
        // {
        //   key: "USDWIN",
        //   sortable: true,
        //   label: this.$t("tableReports.winUSD").toUpperCase(),
        //   class:
        //     this.filterData.currency && this.filterData.currency !== "*"
        //       ? "space-nowrap text-end"
        //       : "d-none",
        // },
        // {
        //   key: "USDPROFIT",
        //   sortable: true,
        //   label: this.$t("tableReports.totalProfitUSD").toUpperCase(),
        //   class:
        //     this.filterData.currency && this.filterData.currency !== "*"
        //       ? "space-nowrap text-end"
        //       : "d-none",
        // },
      ];
    },
    checkAdmins() {
      return this.readAllRoles.includes(this.userRole);
    },
    getUserRole() {
      this.userData = this.$store.getters['user/getUserData'];
      this.userRole = this.userData.role.name;

      if (this.userIsClient) {
        this.filterData.clientId = this.userData.client;
        this.getOperatorClient();
        this.getRoulettesClient();
      } else if (this.userRole === 'OPERATOR') {
        this.filterData.operatorId = this.userData.operator;
      } else {
        this.getClients();
      }

      if (!Object.entries(this.$route.params).length) return;

      this.filterData = { ...this.$route.query };
      this.filtrar();
    },
    async getRoulettesClient() {
      const client = this.userData.client || this.filterData.clientId;
      try {
        await this.fetchClientRoulettes({ id: client });
        if (!this.mapClientRoulettes.length) return (this.roulettes = []);

        const formatData = this.mapClientRoulettes.map((element) => {
          return element.roulette;
        });
        this.roulettes = formatData;
      } catch (err) {
        console.log('Err', err);
      }
    },
    async getRoulettesOperator() {
      const operatorId = this.userData.operator;

      try {
        const { data } = await this.$http.get(
          `operators/${operatorId}/roulettes`
        );

        const formattedData = data.operatorRoullettes.map((element) => {
          return element.roulette;
        });
        this.roulettes = formattedData;
      } catch (err) {
        console.log('Error al obtener ruletas del operador', err);
      }
    },
    async getGames() {
      try {
        if (this.userIsClient) {
          this.getRoulettesClient();
        } else if (this.userRole === 'OPERATOR') {
          this.getRoulettesOperator();
        } else {
          await this.fetchGames({ gameType: this.filterData.gameType });
          this.roulettes = this.mapGames;
        }
      } catch (error) {
        console.log('ERROR GET GAMES', error);
      }
    },
    // ACTIONS
    ...mapActions({
      fetchCurrencies: 'currencies/fetchCurrencies',
      fetchClients: 'clients/fetchClients',
      fetchOperators: 'operators/fetchOperators',
      fetchClientRoulettes: 'clients/fetchClientRoulettes',
      fetchGames: 'games/fetchGames',
      fetchGgrByPlayers: 'reports/fetchGgrByPlayers',
      fetchGgrByPlayersTotalized: 'reports/fetchGgrByPlayersTotalized',
    }),
  },
  watch: {
    playerAdmin(data) {
      this.filterData.isAdmin = data ? data : '';
    },
    perPage() {
      this.skip = 0;
      this.limit = this.perPage;
      this.filtrar();
    },
    'filterData.fromDate': function () {
      const { toDate, fromDate } = this.filterData;

      if (fromDate === toDate) return (this.dateWasChanged = false);

      if (toDate && fromDate) {
        this.dateWasChanged = true;
      }
    },
    'filterData.toDate': function () {
      const { fromDate, toDate } = this.filterData;
      if (fromDate === toDate) return (this.dateWasChanged = false);

      if (fromDate && toDate) {
        this.dateWasChanged = true;
      }
    },
    'filterData.fromHour': function (hour) {
      if (!hour) return (this.filterData.fromDateHour = '');
      this.filterData.fromDateHour = `${this.filterData.fromDate} ${hour}`;
    },
    'filterData.toHour': function (hour) {
      if (!hour) return (this.filterData.toDateHour = '');
      this.filterData.toDateHour = `${this.filterData.toDate} ${hour}`;
    },
  },
  middleware: 'authentication',
};
</script>

<style>
.space-nowrap {
  white-space: nowrap !important;
}
</style>
